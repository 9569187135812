import { useEffect } from 'react';

import routes from '../../routes';

const SummerAcademy = () => {
  useEffect(() => {
    window.location.replace(routes.ACADEMY);
  }, []);

  return null;
};

export default SummerAcademy;
